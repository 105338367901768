<template>
  <div>
  <b-overlay
    :show="isOverlay"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row>
      <b-col md="6" sm="12" v-if="isHidden">
        <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          <span><strong>Perhatian !</strong> <br> Jika ingin mengajukan cuti melahirkan harap melaporkan ke Unit HRD terlebih dahulu agar dapat melakukan pengajuan !</span>
        </div>
      </b-alert>
        <b-card
          no-body
          class="card-payment"
        >
          <b-card-header>
            <b-card-title>Form Cuti Melahirkan</b-card-title>
            <b-card-title class="text-primary" >
              {{ CountLive }} Hari
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- form -->
            <b-form 
              @submit.prevent="SaveLeaves()" 
              @change="countLiveDate()"
            >
              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group
                    label="Tanggal Periode Awal"
                    label-for="leave_date_start"
                  >
                    <flat-pickr
                      id="leave_date_start"
                      v-model="leave_date_start"
                      class="form-control"
                      placeholder="Pilih Tanggal"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
                  <b-form-group
                    label="Tanggal Periode Akhir"
                    label-for="leave_date_to"
                  >
                    <flat-pickr
                      id="leave_date_to"
                      v-model="leave_date_to"
                      class="form-control"
                      placeholder="Pilih Tanggal"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    block
                  >
                    <b-spinner
                      v-show="isSpinner"
                      class="ml-auto"
                      :hidden="!isSpinner"
                      small
                      type="grow"
                    />
                    <div :hidden="isSpinner">
                      <span class="align-middle">Simpan</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <!--/ form -->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isCardShow">
      <b-col md="4" sm="10" :key="maternity.leaves_code" v-for="maternity in maternities">
        <b-card
          no-body
          class="card-apply-job shadow-none"
          :border-variant="status_kode[1][maternity.sg_kode]"
          bg-variant="transparent"
        >
        <b-overlay
        :show="isLoadingData"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
          <b-card-header class="pb-1">
            <b-card-title>{{ maternity.leaves_code}}</b-card-title>
            <b-badge
              pill
              :variant="status_kode[1][maternity.sg_kode]"
            >
              {{ status_kode[0][maternity.sg_kode]}}
            </b-badge>
          </b-card-header>
          <b-card-body>
            <h5 class="apply-job-title">
              {{ maternity.emp_full_name}}
            </h5>
            <b-card-text>
              Mengajukan <strong>{{ maternity.leaves_description }}</strong> sebanyak 
              <strong>{{ (new Date(maternity.leaves_periode_end).getTime() - new Date(maternity.leaves_periode_start).getTime()) / (1000 * 3600 * 24) + 1 }}</strong> hari, dimulai pada tanggal : 

            </b-card-text>
  
            <!-- payment  -->
            <div class="apply-job-package bg-light-primary rounded d-flex justify-content-center">
              <div>
                <h4 class="d-inline mr-25">
                  {{ maternity.leaves_periode_start | moment("DD-MM-YYYY")}} - {{ maternity.leaves_periode_end | moment("DD-MM-YYYY")}}
                </h4>
              </div>
            </div>
            <!--/ payment  -->
            <b-button
              v-show="maternity.status_kode === 'LVS-01'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="warning"
              v-b-modal="'modal-center'"
              @click="sendData(maternity)"
            >
              Ubah Tanggal
            </b-button>
            <b-button
              v-show="maternity.status_kode === 'LVS-01'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-danger"
              @click="confirmText(maternity)"
            >
              Batal
            </b-button>
          </b-card-body>
      </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
  <b-modal
    ref="v-modal"
    ok-variant="danger"
    modal-class="modal-danger"
    hide-footer
    centered
    title="Peringatan !"
  >
    <!-- <b-card-text v-if="isFormRequired">
      Tanggal Mulai, Tanggal Akhir & Jenis Voucher Harus di isi !!!
    </b-card-text>
    <b-card-text v-if="isValid">
      {{ message }}
    </b-card-text> -->
    <b-card-text>
      {{ message }}
    </b-card-text>
  </b-modal>
  <!-- modal vertical center -->
    <b-modal
      id="modal-center"
      centered
      :title="selectedLeavesCode.leaves_code"
      hide-footer
    >
      <b-card-text>
        <b-form @submit.prevent="updateLeaves()">
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group
                label="Tanggal Periode Awal"
                label-for="update_leave_date_start"
              >
                <flat-pickr
                  ref="update_leave_date_start"
                  id="update_leave_date_start"
                  v-model="update_leave_date_start"
                  class="form-control"
                  placeholder="Pilih Tanggal"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group
                label="Tanggal Periode Akhir"
                label-for="update_leave_date_to"
              >
                <flat-pickr
                  ref="update_leave_date_to"
                  id="update_leave_date_to"
                  v-model="update_leave_date_to"
                  class="form-control"
                  placeholder="Pilih Tanggal"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <h5>Total Hari : <strong>{{ CountDate }}</strong> Hari</h5>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                block
              >
                <b-spinner
                  v-show="isSpinner"
                  class="ml-auto"
                  :hidden="!isSpinner"
                  small
                  type="grow"
                />
                <div :hidden="isSpinner">
                  <span class="align-middle">Simpan</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BAlert, BModal, BSpinner, BBadge, BOverlay,
  VBToggle, VBModal,
} from 'bootstrap-vue'
import flatPickr from "vue-flatpickr-component";
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
    flatPickr,
    BModal,
    VBToggle,
    VBModal,
    BSpinner,
    BBadge,
    BOverlay
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      isLoadingData: false,
      isOverlay: true,
      isSpinner: false,
      isHidden: true,
      isCardShow: false,
      leave_date_start: '',
      leave_date_to: '',
      update_leave_date_start: null,
      update_leave_date_to: null,
      employeeData: JSON.parse(localStorage.getItem('userData')),
      message: null,
      selectedLeavesCode: '',
      CountDate: '',
      CountLive: '',
      maternities: [],
      maternityDetil: [],
      CountDateA: [],
      LM: '',
      status_kode: [{
        'LVS-00': 'OPEN', 'LVS-01': 'PROPOSED', 'LVS-02': 'APPROVED', 'LVS-03': 'CANCELED', 'LVS-04': 'REJECTED', 'LVS-05': 'VERIFIED',
      },
      {
        'LVS-00': 'primary', 'LVS-01': 'warning', 'LVS-02': 'info', 'LVS-03': 'secondary', 'LVS-04': 'danger', 'LVS-05': 'success',
      }],
      config: {
                dateFormat: 'd-m-Y',        
              },
    }
  },
  mounted(){
    this.fetchDataMaternity()
    this.countLiveDate()
  },
  methods:{
  
    fetchDataMaternity() {
      axios.post('maternityLeaves/list', {
        employee_code: this.employeeData.employee_code,
      }).then(response => {
        this.maternities = response.data.data
        console.log(this.maternities);
        console.log(response);
        // setTimeout 
        if(response.data.data.length === 0){
            setInterval(() => {    
              this.isHidden = true 
              this.isCardShow = false
              this.isOverlay = false
            }, 2000);  
          } else {
            setInterval(() => {    
              this.isHidden = false 
              this.isCardShow = true
              this.isOverlay = false
            }, 2000); 
          }
        // looping
        this.leaves_code = []
        for (let i = 0; i < this.maternities.length; i++) {
          this.leaves_code.push(
            axios.post('maternityLeaves/detil', {
              leave_code: this.maternities[i].leaves_code
            }).then(response => { 

              this.maternityDetil = response.data.data
              
              var dateA = new Date(response.data.data.leaves_periode_start)
              var dateB = new Date(response.data.data.leaves_periode_end)

              var Difference_In_TimeA = dateB.getTime() - dateA.getTime();
              var Difference_In_DaysA = Difference_In_TimeA / (1000 * 3600 * 24);

              this.CountDateA = Difference_In_DaysA + 1

              console.log(this.CountDateA);
            })
          )
        }
      }).catch(error => {
          console.log(error.response.status)
          if (error.response.status === 400) {
            console.log(error)
          }  
          if (error.response.status === 404) {
            //this.$router.push({ name: "error-404"}).catch(() => {})
              this.isHidden = true 
              this.isOverlay = false
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
    },
    resetForm() {
      this.leave_date_start = null
      this.leave_date_to = null
      this.update_leave_date_start = null
      this.update_leave_date_to = null
    },
    SaveLeaves() {
      // console.log(this.voucher.voucher_id);
      this.isSpinner = true
      axios.post('/maternityLeaves/create', {
        employee_code: this.employeeData.employee_code,
        leave_date_start: this.leave_date_start,
        leave_date_to: this.leave_date_to,
      })
        .then(response => {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          // }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['v-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false
          this.isHidden = false
          this.resetForm()
          this.getMaternityLeaves()
        })
    },
    getMaternityLeaves() {
      axios.post('/maternityLeaves/list', {
        employee_code: this.employeeData.employee_code,
        month_periode: null,
        year_periode: null,
        status: null,
      }).then(response => {
          //this.ToastDataFound()
          console.log(response.data.data);
          this.maternity = response.data.data
        }).catch(error => {
          console.log(error.response.status)
          if (error.response.status === 400) {
            console.log(error)
          }  
          if (error.response.status === 404) {
            this.$router.push({ name: "error-404"}).catch(() => {})
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
      }).finally(() => {
      })
    },
    sendData(maternity) {
      // this.$refs['modal-center'].show()
      this.selectedLeavesCode = maternity.leaves_code
      this.update_leave_date_start = maternity.leaves_periode_start
      this.update_leave_date_to = maternity.leaves_periode_end

      var date1 = new Date(this.update_leave_date_start)
      var date2 = new Date(this.update_leave_date_to)

      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      this.CountDate = Difference_In_Days + 1

      console.log(this.CountDate);
    },
    countLiveDate() {
      if (this.leave_date_to === ''){
        this.CountLive = '0'
        // console.log( '0 hari');
      } else {
        var day1 = new Date(this.leave_date_start)
        var day2 = new Date(this.leave_date_to)

        var DIT = day2.getTime() - day1.getTime();
        var DID = DIT / (1000 * 3600 * 24);

        this.CountLive = DID + 1
        // console.log(this.leave_date_start + ',' + this.leave_date_to + ',' + this.CountLive);
      }
    },
    updateLeaves() {      
      // console.log(this.voucher.voucher_id);
      this.isSpinner = true
      this.isLoadingData = true
      axios.patch('/maternityLeaves/update', {
        leave_code: this.selectedLeavesCode,
        employee_code: this.employeeData.employee_code,
        leave_date_start: this.update_leave_date_start,
        leave_date_to: this.update_leave_date_to,
      })
        .then(response => {
            console.log(response)
            this.fetchDataMaternity()
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          // }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['v-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false
          this.isHidden = false
          this.isLoadingData = false
          this.resetForm()
          this.$bvModal.hide('modal-center')
        })
    },
    confirmText(maternity) {
      this.uLeave = maternity.leaves_code;
      this.$swal({
        title: "Apa anda yakin?",
        text: "Cuti yang sudah dibatalkan tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isOverlay = true
          axios
            .patch("/leaves/cancel", {
              leave_code: this.uLeave,
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Canceled!",
                text: "Cuti anda berhasil dibatalkan.",
                timer: 1500,
              });
            })
            .catch((error) => {
              console.log(error.response.data.message);
            })
            .finally(() => {
              this.getLeaveEmployee();
              this.isOverlay = true
            });
        }
      });
    },
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
